import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../../assets/css/dashboardlayout.css";
import NavigationActions from "redux/navigation/actions";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import AuthActions from "redux/auth/actions";
import { getCategories } from "services/categoryServices";
import mail_icon from "../../assets/siteImages/mail-1.png";
import phone_icon from "../../assets/siteImages/phone-1.png";
import pin_icon from "../../assets/siteImages/pin-1.png";

const { success, error, fetching } = NavigationActions;
const { setuser } = AuthActions;

function FooterUser(props) {
  const { settingdata, token } = props;

  // eslint-disable-next-line
  const [subcate, setsubcate] = useState([]);
  const [flag, setFlag] = useState(true);
  // const [subprinting, setsubprinting] = useState([]);
  const location = useLocation();

  const getcategorise = async () => {
    await getCategories(token).then(data => {
      const subcat = data.data?.filter(val => {
        return val.parent_id === 1;
      });
      setsubcate(subcat);

      if (data.success) {
        success();
      } else {
        error(data.message);
      }
    });
  };
  useEffect(() => {
    getcategorise();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [location.pathname, flag]);
  console.log("setting data", settingdata);
  return (
    <div>
      <div className="row footer">
        <div className="col-lg-3 col-md-6 col-sm-6">
          <h2>LET Us HELP</h2>
          <ul className="footer-menu">
            <li onClick={() => setFlag(!flag)}>
              <Link to="/artworks">ARTWORK INFO & UPLOADS</Link>
            </li>
            <li>
              <Link to="/faq">FAQ’S</Link>
            </li>
            {/* <li>
              <Link to="/our-gallery">GALLERY</Link>
            </li> */}
            <li>
              {/* <Link to="/our-gallery">GALLERY</Link> */}
              <a
                href="https://www.flipsnack.com/56BBDF99E8C/wm-general-brochure-revised/full-view.html"
                target="_blank"
              >
                GALLERY
              </a>
            </li>
            <li>
              <Link to="/news">NEWS</Link>
            </li>
            <li>
              <Link to="/about">ABOUT US</Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <h2>WHAT WE OFFER</h2>
          <ul className="footer-menu">
            <li onClick={() => setFlag(!flag)}>
              <Link to="/">FRIDGE MAGNETS</Link>
            </li>

            {/* {subcate.map((val, index) => {
              return index < 2 ? (
                <li className="text-uppercase" key={index}>
                  <Link to={`/categories/${val.name.replace(" ", "-")}`}>
                    {val.name}
                  </Link>
                </li>
              ) : (
                <></>
              );
            })} */}
            <li>
              <Link to={`/categories/business-card-magnets`}>
                BUSINESS CARD MAGNETS
              </Link>
            </li>
            <li>
              <Link to={`/categories/calendar-fridge-magnets`}>
                CALENDAR MAGNETS
              </Link>
            </li>

            <li>
              <Link to="/artworks">GRAPHIC DESIGN </Link>
            </li>
            {/* <li>
              <Link to="/printing-products">PRINTING & MORE</Link>
            </li> */}
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6">
          <h2>HEAD OFFICE</h2>
          <ul className="footer-menu1">
            <li>
              <div className="img-content">
                <div className="icon">
                  <img
                    // src="https://wholesale-magnets.com.au/wp-content/uploads/2020/06/mail-1.png"
                    src={mail_icon}
                    alt=""
                  ></img>
                </div>
              </div>
              <div className="content1">
                <a href={`mailto:${settingdata?.email}`}>
                  {settingdata?.email}
                </a>
              </div>
            </li>
            <li>
              <div className="icon">
                <img
                  // src="https://wholesale-magnets.com.au/wp-content/uploads/2020/06/phone-1.png"
                  src={phone_icon}
                  alt=""
                ></img>
              </div>
              <div>
                <a href={`tel:${settingdata?.phone_no}`}>
                  {`${settingdata?.phone_no?.substring(
                    0,
                    4
                  )} ${settingdata?.phone_no?.substring(
                    4,
                    7
                  )} ${settingdata?.phone_no?.substring(7, 10)}`}
                </a>
              </div>
            </li>
            <li>
              <div className="icon">
                <img
                  // src="https://wholesale-magnets.com.au/wp-content/uploads/2020/06/pin-1.png"
                  src={pin_icon}
                  alt=""
                ></img>
              </div>
              <div>Shop 8a/1 Exchange Parade, Narellan NSW 2567</div>
            </li>
          </ul>
        </div>
        <div className="col-lg-3 col-md-6 col-sm-6 mb-3">
          <div style={{ width: "100%", height: "240px" }} className="map">
            <iframe
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13224.425236895639!2d150.748564!3d-34.041144!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12f1c1330b92db%3A0xeddccc08d1080aa9!2s8a%2F1%20Exchange%20Parade%2C%20Narellan%20NSW%202567%2C%20Australia!5e0!3m2!1sen!2sin!4v1658325147198!5m2!1sen!2sin"
              width="100%"
              height="100%"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
          </div>
        </div>
        <div className="col-12 text-center ">
          <div className="new_border" style={{ color: "#999898" }}>
            <p>COPYRIGHT 2023. ALL RIGHTS RESERVED.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    ...state.themeChanger,
    token: state.auth.accessToken,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    settingdata: state.settingdata.sdata
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error, fetching, setuser }
    // mapDispatchToProps
  )
)(FooterUser);
