import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: Yup.string()
      .required("Please Enter Name"),
      // .matches(/^[a-zA-Z ]*$/, "Please Enter valid Name"),
    company: Yup.string()
      .required("Please Enter Company Name"),
      // .matches(/^[a-zA-Z ]*$/, "Please Enter valid Company Name"),
    mobile_no: Yup.string()
      .required("Please Enter Mobile Number")
      .matches(
        // /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[- ](?=\d{3})|(?!\d\d[- ]?\d[- ]))\d\d[- ]?\d[- ]?\d{3})$/,
        /^(?:\+?(61))??(?:\((?=.*\)))?(0?[2-57-8])\)? ?(\d\d(?:[-](?=\d{3})|(?!\d\d[-]?\d[-]))\d\d[-]?\d[-]?\d{3})$/,
        // /^\+?(61)?[-.\s]?\d{1}?[-.\s]?\d{4}[-.\s]?\d{4}$/,
        "Please Enter Valid Mobile Number"
      ),
    delivery_postcode: Yup.string()
      .matches(/^[0-9]*$/, "Please Enter valid  delivery postcode")
      .min(4, "Delivery Postcode must be at least 4 digits")
      .max(4, "Delivery Postcode must be at most 4 digits")
      .required("Please Enter Delivery Postcode"),
    email: Yup.string()
      .required("Please Enter Email")
      .email("Please Enter Valid Email")
  }),
  validateOnMount: true,
  mapPropsToValues: props => ({
    name: "",
    company: "",
    mobile_no: "",
    delivery_postcode: "",
    email: ""
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
