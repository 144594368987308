import React, { useEffect, useState } from "react";
import { getProductQuantitiesTPA } from "services/thirdpartyapiServices";
// import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import {
  useLocation,
  useParams,
  withRouter
} from "react-router-dom/cjs/react-router-dom.min";
import { getProductByName } from "services/productServices";
import navigationAction from "redux/navigation/actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { useDispatch } from "react-redux";
import Userdetail from "./userdetail";
import Markers from "./markers";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import Artwork from "./artwork";
import Papers from "./papers";
import Color from "./color";
import InquiryActions from "redux/InquiryData/actions";
import { Helmet } from "react-helmet";
import { addIPAdd, updateIPAdd } from "services/IPAddServices";
import axios from "axios";
import { getCategoryByName } from "services/categoryServices";
const { setAllData, setIpData, setPData, setStep } = InquiryActions;

const { success, error } = navigationAction;

function Detail(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { pdata, token, inquiry } = props;
  const { step, pData, ipData, allData } = inquiry;
  console.log("step check22", step, ipData);
  const [ans, setAns] = useState(
    pData?.pquantity
      ? pData?.pquantity?.split(",")
      : pData?.product_quantity?.split(",")
  );

  // const [ans, setAns] = useState(pData?.pquantity?.split(","));
  console.log("ans check 30", pData, ans);
  let { name, slug } = useParams();
  const [product, setProduct] = useState("");
  name = name.replace(/_/g, " ");
  name = name.replace(/#/g, "/");

  const getSKUData = async sku => {
    await getProductQuantitiesTPA({ sku: sku }).then(res => {
      // console.log("res datas", res?.data?.pquantity);
      if (res.data) {
        if (res.data.length > 0) {
          setAns(
            res?.data[0] === "Select Quantity"
              ? res?.data
              : ["Select Quantity", ...res.data]
          );
        }
      }
    });

    // await axios
    //   .get(
    //     `http://vmh20064.hosting24.com.au/website/print_sms_server_quantity.asp?product=.${sku}`
    //   )
    //   .then(res => {
    //     if (res.status) {
    //       setAns(res.quantities.split(","));
    //     }
    //   });
  };

  const getIPData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log("res check 66", res);
    dispatch(setIpData({ ...ipData, IP_Address: res?.data?.IPv4 || "" }));
  };
  const addIPData = async () => {
    await addIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };
  const UpdateIPData = async () => {
    await updateIPAdd(ipData).then(data => {
      if (data.success) {
        success(data.message);
      } else {
        error(data.message);
      }
    });
  };

  const getProductData = async () => {
    await getProductByName(token, slug, name).then(res => {
      if (res.success) {
        dispatch(setPData(res.data[0]));
        setProduct(res.data[0]);
      } else {
        error(res.message);
      }
    });
  };
  useEffect(() => {
    getIPData();
    if (
      ipData?.IP_Address &&
      ipData?.IP_Address !== "" &&
      ipData?.completedStep === 1
    ) {
      addIPData();
    }
    // eslint-disable-next-line
  }, [step]);
  useEffect(() => {
    ans && ans[0] !== "Select Quantity" && setAns(["Select Quantity", ...ans]);
  }, []);

  console.log("product check 53", product);
  useEffect(() => {
    console.log("inside this useeffect 3");
    if (
      ipData?.IP_Address &&
      ipData.IP_Address !== "" &&
      ipData.completedStep > 1
    ) {
      UpdateIPData();
    }

    // eslint-disable-next-line
  }, [step, ipData.stepName]);

  useEffect(() => {
    console.log("pdata", product);
    if (product.SKU) {
      getSKUData(product.SKU);
    }
    // eslint-disable-next-line
  }, [product?.SKU]);
  useEffect(() => {
    getProductData();
  }, []);
  console.log("allData------------>>>>>>>", allData);
  return (
    <>
      <Helmet>
        <title>{product.product_name}</title>
        <meta name="robots" content="max-image-preview:large" />
      </Helmet>
      {step === 2 ? (
        <div className="row wanting">
          <div className="col-sm-12">
            {console.log("check144", product)}
            <div className="isCatNameTag">
              <h4 className="p-title">
                {/* {!props.history.location.pathname.includes("/printing-products")
                  ? product.product_name
                  : product.name} */}
                {slug?.replace(/-/g, " ")}
              </h4>
            </div>
            <h5>
              <span className="mr-2">{product.product_name}</span>
              {product.size}
            </h5>
            <h3 className="sms-title">Get An Instant Price By SMS Now</h3>
            <div className="col-12 col-md-9 my-2 px-0">
              <h4 className="main-title">How many are you wanting ?</h4>
              <select
                className="want_width"
                value={allData.quantity}
                onChange={e => {
                  dispatch(
                    setAllData({
                      ...allData,
                      quantity: e.target.value
                    })
                  );
                }}
              >
                {ans?.map((val, ind) => {
                  return (
                    <option key={ind} value={val}>
                      {val}
                    </option>
                  );
                })}
                {console.log("dsssdsdsdssddsajvakdmhdha")}
              </select>
            </div>
            <div className="btn-align mb-3">
              <button
                type="button"
                className="btn btn_base_style my-2 mx-1"
                // onClick={() => {
                //   allData?.category === "ENVELOPES"
                //     ? dispatch(setStep(step - 0.5))
                //     : allData?.category === "FLYERS"
                //     ? dispatch(setStep(step - 0.4))
                //     : dispatch(setStep(step - 1));
                //   dispatch(
                //     setIpData({
                //       ...ipData,
                //       completedStep: ipData.completedStep - 1,
                //     })
                //   );
                // }}
                onClick={() => {
                  console.log(allData, "all data in on click");
                  if (allData.category === "ENVELOPES") {
                    dispatch(setStep(step + 0.5));
                    dispatch(
                      setIpData({
                        ...ipData,
                        completedStep: ipData.completedStep - 1
                      })
                    );
                  } else if (allData.category === "FLYERS") {
                    dispatch(setStep(step + 0.6));
                    dispatch(
                      setIpData({
                        ...ipData,
                        completedStep: ipData.completedStep - 1
                      })
                    );
                  } else {
                    props.history.go(-1);
                    dispatch(setPData([]));
                    dispatch(setAllData([]));
                  }
                }}
              >
                Previous
              </button>
              <button
                type="button"
                className="btn btn_base_style my-2"
                onClick={() => {
                  // allData?.category === "SHOPPING LIST TO DO LISTS"
                  // ? setStep(step - 0.5)
                  // eslint-disable-next-line

                  if (allData?.quantity) {
                    if (allData?.quantity == undefined) {
                      setAllData({
                        ...allData,
                        quantity: ans[1]
                      });
                    }
                    if (
                      !location.pathname.includes("/printing-products") &&
                      allData?.product_name?.includes("DL") ||
                      allData?.product_name?.includes("A5") ||
                      allData?.product_name?.includes("A6")
                    ) {
                      dispatch(setStep(step + 1));
                    } else {
                      setAllData({
                        ...allData,
                        wrapping: "No"
                      });
                      dispatch(setStep(step + 2));
                    }
                    dispatch(
                      setIpData({
                        ...ipData,
                        stepName: "Quantity Selected",
                        completedStep: ipData.completedStep + 1
                      })
                    );
                  } else {
                    error("Please select quantity");
                  }
                }}
              >
                Next
              </button>
            </div>
            <div className="">
              <span className="cateogoryDescp">
                <div
                  dangerouslySetInnerHTML={{
                    __html: product.description
                  }}
                  className="notes-text"
                />
              </span>
            </div>
          </div>
        </div>
      ) : step === 2.5 ? (
        <Color />
      ) : step === 2.6 ? (
        <Papers />
      ) : step === 3 ? (
        <Artwork />
      ) : step === 3.5 ? (
        <Markers />
      ) : (
        <GoogleReCaptchaProvider
          reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          scriptProps={{
            async: false,
            defer: false,
            appendTo: "head",
            nonce: undefined
          }}
        >
          <Userdetail />
        </GoogleReCaptchaProvider>
      )}
    </>
  );
}
const mapStateToProps = state => {
  console.log("check state 168", state);
  return {
    ...state.themeChanger,
    token: state.auth.accessToken, //!= null? state.auth.accessToken : state.auth.token,
    user: state.auth.user,
    isFetching: state.navigation.isFetching,
    inquiry: state.inquiry
  };
};
// const mapDispatchToProps = () => {};

export default compose(
  withRouter,
  // enhancer,
  connect(
    mapStateToProps,
    { success, error }
    // mapDispatchToProps
  )
)(Detail);
